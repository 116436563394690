const $ = require('jquery');
window.jQuery = $;

import Cookies from 'js-cookie';

const objectFitImages = require('object-fit-images');
const fancybox = require('@fancyapps/fancybox');
const Flickity = require('flickity-bg-lazyload');
require('flickity-imagesloaded');
require('flickity-fade');
const Headroom = require('headroom.js');
require('jquery-pjax');
var autocomplete = require('jquery-ui/ui/widgets/autocomplete');
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import LazyLoad from "vanilla-lazyload";
import './loadHtmx';

const initLazyLoad = () => {
	var lazyLoadInstance = new LazyLoad({
			// Your custom settings go here
	});
}
window.initLazyLoad = initLazyLoad;


/*=============================================
= Recaptcha
=============================================*/
if($('.g-recaptcha').length > 0) {

	var jsUrl = `//www.google.com/recaptcha/api.js?onload=callbackRecaptcha&render=explicit`;
	var jsScript = document.createElement('script');
	jsScript.async = true;
	jsScript.src = jsUrl;
	document.querySelector('head').appendChild(jsScript);

	const callbackRecaptcha = () => {
		$.each($('.g-recaptcha'), function(idx, itm) {
			grecaptcha.render($(itm).attr('id'), {
				'sitekey' : $(itm).data('sitekey'),
				'theme' : 'light'
			});
		});
	};
	window.callbackRecaptcha = callbackRecaptcha;

}



/*=============================================
= Recaptcha ends
=============================================*/


// const jQueryBridget = require('jquery-bridget'); //allows usage of Flickity as a jQuery plugin

// document.addEventListener('DOMContentLoaded', (event) => {
const ready = function() {

	document.onreadystatechange = function () {
		if (document.readyState === 'complete') {
			// Enable global transitions
			document.body.classList.remove('disable-transitions');
		}
	}


	$.fancybox.defaults.btnTpl.close = '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">' +
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="M4.929 3.515a1 1 0 00-1.414 1.414L10.585 12l-7.07 7.071a1 1 0 101.414 1.414l7.07-7.07 7.072 7.07a1 1 0 001.414-1.414l-7.07-7.07 7.07-7.072a1 1 0 00-1.414-1.414L12 10.585l-7.071-7.07z" clip-rule="evenodd"/></svg>' +
  '</button>';

	$.fancybox.defaults.btnTpl.smallBtn = '<button data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"><path fill-rule="evenodd" d="M4.929 3.515a1 1 0 00-1.414 1.414L10.585 12l-7.07 7.071a1 1 0 101.414 1.414l7.07-7.07 7.072 7.07a1 1 0 001.414-1.414l-7.07-7.07 7.07-7.072a1 1 0 00-1.414-1.414L12 10.585l-7.071-7.07z" clip-rule="evenodd"/></svg>' +
  '</button>';


	/*=============================================
	= LazyLoad
	=============================================*/
	setTimeout(function() {
			initLazyLoad();
	}, 800);

	/*=============================================
	= object-fit-polyfill
	=============================================*/
	objectFitImages();


	/*=============================================
	= add sticky class on scroll
	=============================================*/

	apply_stickies();

	var detectScroll = function (e) {
		apply_stickies();
	};

	function debounce(fn, delay) {
		var timer = null;
		return function () {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function () {
				fn.apply(context, args);
			}, delay);
		};
	}

	window.addEventListener('scroll', debounce(detectScroll, 100), false);

	function apply_stickies() {
		var _$stickies = [].slice.call(document.querySelectorAll('.js-sticky'));
		_$stickies.forEach(function (_$sticky) {
			if (CSS.supports && CSS.supports('position', 'sticky')) {
				apply_sticky_class(_$sticky);
			}
		})
	}

	function apply_sticky_class(_$sticky) {
		var currentOffset = _$sticky.getBoundingClientRect().top;
		var stickyOffset = parseInt(getComputedStyle(_$sticky).top.replace('px', ''));
		var isStuck = currentOffset <= stickyOffset;

		_$sticky.classList.toggle('is-sticky', isStuck);
	}



	/*=============================================
  Header scroll (headroom.js)
  =============================================*/

	//polyfill for IE11
	if (typeof Object.assign !== 'function') {
		// Must be writable: true, enumerable: false, configurable: true
		Object.defineProperty(Object, "assign", {
			value: function assign(target, varArgs) { // .length of function is 2
				'use strict';
				if (target === null || target === undefined) {
					throw new TypeError('Cannot convert undefined or null to object');
				}

				var to = Object(target);

				for (var index = 1; index < arguments.length; index++) {
					var nextSource = arguments[index];

					if (nextSource !== null && nextSource !== undefined) {
						for (var nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
				}
				return to;
			},
			writable: true,
			configurable: true
		});
	}

	if (Headroom.cutsTheMustard) {
		const headroom = new Headroom(document.body, {
			offset: 128
		});
		headroom.init();
	}



	/*=============================================
  Header scroll (headroom.js)
  =============================================*/

  //polyfill for IE11
  if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        'use strict';
        if (target === null || target === undefined) {
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource !== null && nextSource !== undefined) {
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  if(Headroom.cutsTheMustard) {
    const headroom = new Headroom(document.body, {
			offset: 128
		});
    headroom.init();
  }


	/*=============================================
	  Site nav
	  =============================================*/
	const siteNavToggle = document.querySelector('.js-navs-toggle');
	const siteNavClose = document.querySelector('.js-navs-close');

	//Toggle open
	if (siteNavToggle) {
		siteNavToggle.addEventListener("click", (event) => {
			document.body.classList.add('site-nav-is-open');
		});
	}


	//Close
	if (siteNavClose) {
		siteNavClose.addEventListener("click", (event) => {
			document.body.classList.remove('site-nav-is-open');
		});
	}



	/*=============================================
	= Site search
	=============================================*/
	(() => {
		let ssLoaded = false;
		const bootstrapSiteSearch = () => {
			if (ssLoaded) {
				return
			}
			ssLoaded = true;
			import('./site-search.js').then(() => {
			}).catch(() => {
			});
		};
		$(".js-site-search-input, .js-site-search-toggle").one('mouseover focus click', bootstrapSiteSearch);
	})();





	/*=============================================
	= Cart panel
	=============================================*/
	$(document).on('click', '.js-cart-open', function(){
		$('body').addClass('cart-is-open');
	});

	$(document).on('click', '.js-cart-close, .js-cart-bg', function(){
		$('body').removeClass('cart-is-open');
	});

	$(document).on('click', '.js-side-matches-close, .js-side-matches-bg', function(){
		$('body').removeClass('side-matches-is-open');
	});

	$(document).on('click', '.js-cart-added-close', function(){
		$('.js-cart-added').removeClass('visible');
	});



	/*=============================================
	= Tabs
	=============================================*/

	//closest() polyfill for legacy IE
	if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector;
	if (!Element.prototype.closest) Element.prototype.closest = function (selector) {
		var el = this;
		while (el) {
			if (el.matches(selector)) {
				return el;
			}
			el = el.parentElement;
		}
	};


	/**
	 * @function Tabs()
	 *
	 * Simple tabbed content UI component
	 *
	 * @param args {object} Settings for controlling the functionality of the component
	 * @returns bindEventListeners {function} Event listeners for the component
	 */

	function Tabs(args) {
	  // Scope-safe constructors
	  if (!(this instanceof Tabs)) {
		return new Tabs();
	  }

	  /**
	   * Default component settings
	   *
	   * @param container {string} Classname for container of the entire component
	   * @param trigger {string} Element that toggles content
	   * @param content {string} Classname for the content
	   */
	  const defaults = {
		container: '[data-tab-component]',
		trigger: '[role="tab"]',
		content: '[role="tabpanel"]'
	  };

	  // If there are no settings overrides
	  const settings = (typeof args !== 'undefined') ? args : defaults;

	  /**
	   * @function toggle()
	   *
	   * Handles the displaying/hiding of content
	   *
	   * @returns null
	   */
	  const toggle = function () {
		const parent = this.closest(settings.container),
		  target = this.getAttribute('aria-controls'),
		  content = document.getElementById(target),
		  toggles = parent.querySelectorAll(settings.trigger),
		  all_content = parent.querySelectorAll(settings.content);

		// Update visibility
		for (let i = 0, len = toggles.length; i < len; i++) {
		  toggles[i].setAttribute('aria-selected', 'false');
		  all_content[i].setAttribute('aria-hidden', 'true');
		}

		this.setAttribute('aria-selected', 'true');
		content.setAttribute('aria-hidden', 'false');
	  };

	  /**
	   * @function bindEventListeners()
	   *
	   * Attach event listeners
	   *
	   * @returns null
	   */
	  const bindEventListeners = function () {
		const trigger = document.querySelectorAll(settings.trigger);

		for (let i = 0, len = trigger.length; i < len; i++) {
		  trigger[i].addEventListener('click', function (event) {
			toggle.call(this);
		  });

		  trigger[i].addEventListener('keydown', function (event) {
			if (event.which == 13) {
			  toggle.call(this);
			}
		  });
		};
	  };

	  return bindEventListeners();
	}


	// Create an instance of tab component
	const tabs = new Tabs();



	/*=============================================
	= Product filters sidebar (mobile)
	=============================================*/
	const sidePanelActiveClass = 'side-panel-is-open';
	const sidePanelOpen = document.querySelectorAll('.js-side-panel-open');
	const sidePanelClose = document.querySelectorAll('.js-side-panel-close');

	if (sidePanelOpen && sidePanelClose) {
		//Open
		sidePanelOpen.forEach(function(button) {
			button.addEventListener("click", (event) => {
				document.body.classList.add(sidePanelActiveClass);
			});
		});

		//Close
		sidePanelClose.forEach(function(button) {
			button.addEventListener("click", (event) => {
				document.body.classList.remove(sidePanelActiveClass);
			});
		});
	}



	/*=============================================
	= Product gallery slides
	=============================================*/
	// Flickity.setJQuery($);
	// jQueryBridget('flickity', Flickity, $);

	if ($('.js-product-gallery').length) {
			let productGallery = document.querySelector('.js-product-gallery');
			let productSlides = new Flickity( productGallery, {
					pageDots: false,
					prevNextButtons: false,
					imagesLoaded: true,
					setGallerySize: false,
					bgLazyLoad: true,
					cellAlign: "left",
					fade: true
			});
			let productSlidesData = Flickity.data( productGallery );

			//Slider arrows
			if ($('.js-product-gallery-nav-lateral').length) {
				$('.product__gallery-nav__lateral-button').on('click', function () {
						let slideDirection = $(this).data('direction');
						if (slideDirection == 'prev') {
								productSlides.previous();
						} else {
								productSlides.next();
						}

						$('.product__gallery-nav__lateral-button.is-disabled').removeClass('is-disabled');

						if ((productSlidesData.selectedIndex + 1) == 1 || (productSlidesData.selectedIndex + 1) == productSlidesData.slides.length) {
								$(this).addClass('is-disabled');
						}
				});
			}

			//Slider thumbs
			if ($('.js-product-gallery-thumbs').length) {
					let $productSlidesNav = $('.js-product-gallery-thumbs');
					let $productSlidesNavItems = $productSlidesNav.find('.product__gallery-thumb');

					productSlides.on('select', function() {
						$productSlidesNav.find('.is-selected').removeClass('is-selected');
						$productSlidesNavItems.eq(productSlidesData.selectedIndex).addClass('is-selected');
					});

					$productSlidesNav.on('click', '.product__gallery-thumb', function () {
							let index = $(this).index();
							productSlides.select(index);
					});
			}
	}


	/*=============================================
    = Product detail - payment methods
    =============================================*/
	$(document).on('click', '.js-show-payment-methods', function (ev) {
		let id = $(this).data('id');
		$.fancybox.open({
			src: '#all_payment_methods-' + id,
			type: 'inline',
			touch: false,
			toolbar: false,
			smallBtn: true,
		});
	});


	/*=============================================
    = Student beds - region filters
    =============================================*/
	$('.js-student-beds-region').on('change', function() {
		$("#js-city-form").submit();
	});

	if ($.support.pjax) {
		$.pjax.defaults.timeout = 3000;

		$(document).on('submit', '#js-city-form', function(event) {

			$.pjax.submit(event, '#js-student-beds-results', {
				scrollTo: false,
				container: '#js-student-beds-results',
				fragment: '#js-student-beds-results',
			});

			$("#js-student-beds-results").on('pjax:complete', function() {
				if(!$('.js-student-beds-start').hasClass("hidden")) {
					$('.js-student-beds-start').addClass('hidden');
				}
				$('.js-student-beds-results').removeClass('visuallyhidden');

				window.initLazyLoad();
			});




		})

	}


		$(document).on('click', '.js-variant-btn', function(ev) {
        var id = $(this).data('id');
        var container = $(this).closest('.js-product-card');
        $(container).find('.js-variant-content').hide();
        $(container).find('.js-variant-content-' + id).show();

				var containerBar = $('.js-product-bar');
				$(containerBar).find('.js-variant-content-bar').hide();
        $(containerBar).find('.js-variant-content-bar-' + id).show();
    });
	$(document).on('change', '.js-side-matches-input', function (ev) {
		var price = $(this).val();
		var id = $(this).attr('data-id');
		$(document).find('.js-side-matches-add-to-cart').attr('data-id', id);
		$(document).find('.js-side-matches-price').html(price);
	});
	/**
	 * Cart js
	 */
	/*
	$(document).on('click', '.js-add-to-cart', function(ev) {

		if ($(this).data('placement') == 'mobilebar') {
			var id = $('.product').find('.js-variant-btn:checked').data('id');
		} else {
			var container = $(this).closest('.js-product-card');
			var id = $(container).find('.js-variant-btn:checked').data('id');
		}


		$.ajax({
			data: {
				id: id,
			},
			method: 'get',
			url: '/cart/post/check/complimentary',
		}).done(function (data) {
			if(data.hasComplimentaryVariant){
				$('body').find('.js-side-matches').html(data.complimentaryVariantHtml);
				$('body').addClass('side-matches-is-open');
			}else{
				addItemToCart(id, true);
			}
		});

	});
	$(document).on('click', '.js-side-matches-add-to-cart', function(ev) {
		var ids = $(this).attr('data-id');
		ids = ids.split(',');
		var totalItem = ids.length
		for (let i = 0; i < totalItem; i++) {
			var isLast = (totalItem - 1) == i ? true : false;
			addItemToCart(ids[i], isLast);
		}
		$('body').removeClass('side-matches-is-open');
	});
	function addItemToCart(id, isLast){
		$.ajax({
			data: {
				id: id,
				qty: 1,
			},
			method: 'get',
			url: '/cart/post/cart-item/add',
		}).done(function (data) {

			if (data.isOutOfStock) {
				$('#out-of-stock-message').find('.js-message').html(data.outOfStockMessage);
				$.fancybox.open({
					src: '#out-of-stock-message',
					type: 'inline',
					touch: false,
				});
			}else if(isLast){
				$('.js-cart-added').addClass('visible');
				$('body').removeClass('headroom--unpinned');
				$('body').addClass('headroom--pinned');

				setTimeout(function() {
					$('.js-cart-added').removeClass('visible');
				}, 4000);
				$('.js-cart-count').text(data.cart.objJsonOrderItems.length);
			}
			$('.js-cart-mini').html(data.miniCartHtml);
		});
	}

	$(document).on('click', '.js-cart-remove', function(ev) {
		var orderItemContainer = $(this).closest('.js-cart-item');
		var id = $(orderItemContainer).data('id');
		$(orderItemContainer).remove();

		$.ajax({
			type: 'GET',
			url: '/cart/post/cart-item/delete',
			data: {
				id: id,
			},
			success : function(data) {

				if(data.cart.objJsonOrderItems.length == 0) {
					$(".js-products-no-results").removeClass("hidden");
				}


				$('.js-cart-count').text(data.cart.objJsonOrderItems.length);
				$('.js-cart-mini-footer').html(data.miniCartSubtotalHtml);
			}
		});
		return false;
	});
 	*/
	/**
	 * Cart js ends
	 */


	// $(document).on('click', '.js-cart-qty-btn', function (ev) {
	// 	var orderItemContainer = $(this).closest('.js-cart-item');
	// 	var oldValue =  $(orderItemContainer).find('.js-cart-qty').val();
	// 	if ($(this).hasClass('plus')) {
	// 		var newVal = parseFloat(oldValue) + 1;
	// 	} else {
	// 		// Don't allow decrementing below zero
	// 		if (oldValue > 1) {
	// 			var newVal = parseFloat(oldValue) - 1;
	// 		} else {
	// 			newVal = 1;
	// 		}
	// 	}

	// 	$(orderItemContainer).find('.js-cart-qty').val(newVal);
	// 	$(orderItemContainer).find('.js-cart-qty').trigger('change');
	// });

	// $(document).on('change', '.js-cart-qty', function(ev) {
	// 	var orderItemContainer = $(this).closest('.js-cart-item');
	// 	var id = $(orderItemContainer).data('id');
	// 	var qty = $(this).val();
	// 	var price = $(this).data('price');

	// 	if (typeof window._ajaxCartQty != 'undefined') {
	// 		window._ajaxCartQty.abort();
	// 	}
	// 	window._ajaxCartQty = $.ajax({
	// 		type: 'GET',
	// 		url: '/cart/post/cart-item/qty',
	// 		data: {
	// 			id: id,
	// 			qty: qty,
	// 		},
	// 		success : function(data) {
	// 			if (data.isOutOfStock) {
	// 				$('#out-of-stock-message').find('.js-message').html(data.outOfStockMessage);
	// 				$.fancybox.open({
	// 					src: '#out-of-stock-message',
	// 					type: 'inline',
	// 					touch: false,
	// 				});
	// 				$('.js-cart-mini').html(data.miniCartHtml);
	// 			} else {
	// 				var formatter = new Intl.NumberFormat('en-NZ', {
	// 					style: 'currency',
	// 					currency: 'NZD',
	// 				});
	// 				$(orderItemContainer).find('.js-subtotal span').text(formatter.format(qty * price));
	// 				$('.js-cart-mini-footer').html(data.miniCartSubtotalHtml);
	// 			}
	// 		}
	// 	});
	// 	return false;
	// });

	if (window.location.hash == '#cart') {
	    $('body').addClass('cart-is-open');
	}


	/*=============================================
    = Notification dialog for COVID-19
    =============================================*/
		$(document).on('click', '.js-notification-close', function(ev) {
			var cookieKey = $('#notification').data('cookie')
			Cookies.set(cookieKey, 1, { expires: 3 });

			$('body').removeClass('notification-is-active');
			$('#notification').remove();
		});



}

//
if (document.readyState !== 'loading') {
	ready();
} else {
	document.addEventListener('DOMContentLoaded', ready);
}


export default $;
